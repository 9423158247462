export const menuItems = [
  {
    path: "/",
    text: "Начало",
  },
  {
    path: "/products",
    text: "Продукти",
  },
  {
    path: "/infos",
    text: "Информация",
  },
  {
    path: "/contact",
    text: "Контакти",
  },
  {
    path: "/about",
    text: "За нас",
  },
]

