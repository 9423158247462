import * as React from "react"
import {
  FaFacebookSquare as Facebook,
  FaInstagram as Instagram,
  FaTwitterSquare as Twitter,
} from "react-icons/fa"

export const menuItems = [
  {
    path: "/",
    text: "Начало",
  },
  {
    path: "/products",
    text: "Продукти",
  },
  {
    path: "/blogs",
    text: "Информация",
  },
  {
    path: "/contact",
    text: "Контакти",
  },
  {
    path: "/about",
    text: "За нас",
  },
]

export const socialItems = [
  {
    path: "https://www.facebook.com/venzel.bg",
    icon: <Facebook />,
  },
  {
    path: "https://www.instagram.com/venzel.bg/",
    icon: <Instagram />,
  },
  {
    path: "https://twitter.com/venzel_bg",
    icon: <Twitter />,
  },

]
