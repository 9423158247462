import * as React from "react"
import { Link } from "gatsby"
import { socialItems } from "../../constants/links"
import { FooterStyles } from "./FooterStyles"

const Footer = () => {
  return (
    <FooterStyles>
      <div className="container">
        
        <div className="social__menu">
          <ul>
            {socialItems.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    href={item.path}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    {item.icon}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="moreinfo">
          <p>
            <Link to="/terms" activeClassName="menu__item--active">
                <span>{"   "}|{"   "}</span>
                Общи условия
                <span>{"   "}|{"   "}</span>
            </Link>
            <Link to="/cookies" activeClassName="menu__item--active">
                <span>{"   "}|{"   "}</span>
                Бисквитки
                <span>{"   "}|{"   "}</span>
            </Link>   
            <Link to="/privacy" activeClassName="menu__item--active">
                <span>{"   "}|{"   "}</span>
                Поверителност на данни
                <span>{"   "}|{"   "}</span>
            </Link>  
          </p>
        </div>
        <div className="overCopyright">
          <p>
           Създаден от {" "}
            <a
              href="https://www.venzel.bg"
              target="_blank"
              rel="noreferrer"
            >
               <span>V</span>enzel
            </a>
          </p>
        </div>
        <div className="copyright">
          <p>        
            <span>{"   "}{"&"}{"   "}</span>
            Обновен {" "} &copy; {new Date().getFullYear().toString()}{" "}  
          </p>
        </div>
      </div>
    </FooterStyles>
  )
}

export default Footer
